<template lang="pug">
  include ../plugins/pug/btn

  div(
    v-show="isShowPopup"
    @closePopup="isShowPopup = false"
    class="popup"
    @click="removePopup"
  )
    div.dialog__window.popup__wrapper
      div.dialog__window-top
        span
      div.dialog__window-content
        div.popup__wrapper.text-center
          div.text-xl.popup__desc
            p Thank you for reaching out!
            p Your submission has been successfully received. Our specialists will get in touch with you shortly.
          +btn-icon('Ok', 'play__icon.svg')(@click="removePopup")
</template>

<script>
export default {
  name: 'ThanksPopup',
  data () {
    return {
      isShowPopup: false
    }
  },
  methods: {
    removePopup () {
      this.isShowPopup = false
    },
    waitPopup () {
      this.isShowPopup = true
      setTimeout(() => {
        this.isShowPopup = false
      }, 4000)
    }
  }
}
</script>
